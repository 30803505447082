<template>
    <b-row>
        <slot name="add">
            <b-col lg="12" class="mb-1">
                <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                    <b-card-text class="text-center">
                        <b-container>
                            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/decs/add">
                                <feather-icon class="text-primary mr-50 align-middle" icon="PlusSquareIcon" size="30"/>
                                <span class="text-primary align-middle">Ajouter Dec</span>
                            </b-button>
                        </b-container>
                    </b-card-text>
                </div>
            </b-col>
        </slot>

        <b-col lg="12">
            <b-card :style="statutStyles">
                <!-- table -->
                <vue-good-table
                    :columns="columns"
                    :rows="decs"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :sortOptions="sortOptions"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table bordered tableau"
                    :ref="fullVgtContext"
                    
                    
                    >
                    
                >
                
                    <template #emptystate>
                        <div class="text-center"> Aucun DEC n'a été trouvé </div>
                    </template>

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="editDec(props.row.id)"
                            >
                                <feather-icon icon="Edit2Icon" size="1x"/>
                            </b-button>

                            <b-button
                                size="sm"
                                v-if="deletable(props.row)"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="confirmDeleteDec(props.row.id, props.row.num != null ? props.row.num : props.row.titre, removeDecFromList)"
                            >
                                <feather-icon icon="TrashIcon" size="1x"/>
                            </b-button>
                        </span>

                        <template v-else-if="props.column.field === 'statut'">
                            <div  class="text-nowrap" :style="{backgroundColor: props.row.statut.color}">{{props.row.statut.libelle}}</div>
                        </template>
                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> sur {{ props.total }} DECs </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>

                    <!-- <template slot="column-filter" slot-scope="props">
                        <span v-if="props.column.field == 'dateValidationChargeAffaire'" @input="handleCustomFilter">TODO</span>
                    </template> -->
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'

    import remember_filters_mixins from "@/views/lists/remember_filters_mixins.js"

    import decApi from "@/api/decApi"
    import miscApi from "@/api/miscApi"
    import { formatNumber, itemMatch, sortObjectsOnAttribute,getBgColor } from "@/services/utils"
    import { confirmDeleteDec, deleteDec } from "@/views/setups/dec"

    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import { mapState, mapGetters, mapMutations } from "vuex"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { props } from 'vue-prism-component'

    const vgtContext = "listDec"

    export default {
        mixins: [remember_filters_mixins],
        props: {
            context: {default: () => {}}
        },

        components: {
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                vgtContext,
                decs: [],
                sortOptions: {enabled: true, initialSortBy: [{field: 'dateValidationChargeAffaire', type: 'desc'}]},
                pageLength: 25,
                columns: [
                    {
                        label: 'Date',
                        field: 'dateValidationChargeAffaire',
                        type: 'date',
                        dateInputFormat: 'dd/MM/yyyy',
                        dateOutputFormat: 'dd/MM/yyyy',
                        filterOptions: {enabled: false},
                    },
                    {
                        label: 'Num DEC',
                        field: 'num',
                        filterOptions: {enabled: true,placeholder: 'DEC-...',},
                        
                    },
                    {
                        label: 'Num Affaire',
                        field: 'affaire.num',
                        filterOptions: {enabled: true,placeholder: 'Affaire ...',},
                    },
                    {
                        label: 'Intitulé',
                        field: 'titre',
                        filterOptions: {enabled: true,placeholder: 'Intitulé...',},
                    },
                    {
                        label: 'Client',
                        field: 'client.nom',
                        filterOptions: {enabled: true,placeholder: 'Client...',},
                    },
                    {
                        label: 'Agence',
                        field: 'agence',
                        filterOptions: {enabled: true,placeholder: 'Agence...', filterFn: (agence, filter) => itemMatch(agence, ["nom", "numero"], filter)},
                        formatFn: (agence) => agence.numero + '-' + agence.nom,
                        sortFn: (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'nom')

                    },
                    {
                        label: 'Montant HT',
                        field: 'prixVenteHT',
                        type: 'decimal',
                        filterOptions: {enabled: true,placeholder: 'Montant...',},
                        formatFn: (prix) => formatNumber(prix)
                    },
                    {
                        label: 'Statut',
                        field: 'statut',
                        filterOptions: {enabled: true, placeholder: 'Statut', filterDropdownItems: [], filterFn: (selected, code) => selected.code == code},
                        sortFn: (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'libelle'),
                        //class variable
                        tdClass: this.tdClassFunc,
                        
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        width: '10%',
                        sortable: false
                    },
                ],

                
                
            }
        },



        beforeMount() {
            this.refreshDecs();
            this.refreshStatuts();

            // si l'utilisateur voit les decs de tous les chargés d'affaire, on rajoute la colonnne
            if(!this.showDecsOnlyUser) {
                this.columns.splice(3, 0, {
                    label: "Chargé Affaire",
                    field: "userValidationChargeAffaire",
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Chargé Affaire ...',
                        filterFn: (user, filter) => itemMatch(user, ["firstname", "lastname", "username"], filter),
                    },
                    formatFn: (userValidationChargeAffaire) => `${userValidationChargeAffaire.firstname} ${userValidationChargeAffaire.lastname} - ${userValidationChargeAffaire.username}`,
                    sortFn:  (x, y, col, rowX, rowY) => sortObjectsOnAttribute(x,y,'firstname')
                })
            }
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, deleteDec, confirmDeleteDec, formatNumber}
        },

        computed: {
            ...mapState({
                user: state => state.data.user,
                statuts: state => state.data.decStatuts,
            }),
            ...mapGetters("data", ["showDecsOnlyUser"]),
            statutStyles() {
                let statutStyles = this.statuts.reduce((acc, statut) => {
                    let attr = '--bg-' + statut.code;
                    acc[attr] = statut.color;
                    attr = '--color-' + statut.code;
                    acc[attr] = getBgColor(statut.color)['color'];
                    return acc;
                },{});
                return statutStyles;
            }
        },

        methods: {

            tdClassFunc(row) {

                return row.statut.code
            },            
            
            ...mapMutations("data", {
                setStatuts: "setDecStatuts",
            }),
            handleCustomFilter(value) {
                // TODO filtre date
            },

            async refreshDecs() {
                /* console.log(process.env.NODE_ENV == "development") */
                this.decs = await decApi.getDecs(this.showDecsOnlyUser ? {'affaire.userChargeAffaire' : this.user.id, ...this.context} : this.context);
                this.$emit("refresh-decs", this.decs.filter(d => d.dateValidationComptabilite != null))
            },

            async refreshStatuts() {
                /* console.log(process.env.NODE_ENV == "development") */
                let statuts = await miscApi.getDecStatuts();
                this.setStatuts(statuts);
                this.columns.find(c => c.field == "statut").filterOptions.filterDropdownItems = this.statuts.map(s => ({text: s.libelle, value: s.code}))
            },

            editDec(decId) {
                this.saveFilters();
                this.$router.push({path: `/decs/${decId}/edit` })
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            deletable(dec) {
                // on ne supprime que les DECs brouillon, et si l'user est Chargé Affaire, alors il ne supprime que les siens
                return dec.statut.code == "BROUILLON" && (!this.showDecsOnlyUser || dec.userValidationChargeAffaire.id == this.user.id)
            },

            removeDecFromList(decId) {
                let index = this.decs.findIndex(dec => dec.id == decId)
                if(index != -1)
                    this.decs.splice(index, 1)
            },
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }

    td.DRAFT {
        background-color: var(--bg-DRAFT);
        color: var(--color-DRAFT) !important;
    }

    td.CHARGE_AFFAIRE {
        background-color: var(--bg-CHARGE_AFFAIRE);
        color: var(--color-CHARGE_AFFAIRE) !important;
    }

    td.COMPTABILITE {
        background-color: var(--bg-COMPTABILITE);
        color: var(--color-COMPTABILITE) !important;
    }

    td.DIRECTION {
        background-color: var(--bg-DIRECTION);
        color: var(--color-DIRECTION) !important;
    }

    td.BROUILLON {
        background-color: var(--bg-BROUILLON);
        color: var(--color-BROUILLON) !important;
    }
    
</style>